<template>
    <div id="siteTagsHealth" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="siteTagsHealth"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="troubleshooting_tagsHealthListSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->
                        <div
                            class="kt-content kt-grid__item kt-grid__item--fluid"
                        >
                            <div class="kt-portlet" v-if="arrayOfTags && arrayOfTags.length > 0">
                                <div class="kt-portlet__body kt-portlet__body--fit">
                                    <div class="row row-no-padding row-col-separator-xl">
                                        <div class="col-md-6 col-lg-6 col-xl-6">
                                            <div class="kt-widget1">
                                                <div class="kt-widget1__item">
                                                    <div class="kt-widget1__info" data-cy="home_widget_info_Applications">
                                                        <h3 class="kt-widget1__title">{{ $t("troubleshooting_lowCoverageTitle") }}</h3>
                                                        <span class="kt-widget1__desc">{{ $t("troubleshooting_lowCoverageSubTitle") }}</span>
                                                    </div>
                                                    <span class="kt-widget1__number kt-font-danger" style=" font-size: 2rem; ">{{ numberOfTagWithBadCoverage }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-lg-6 col-xl-6">
                                            <div class="kt-widget1">
                                                <div class="kt-widget1__item">
                                                    <div class="kt-widget1__info" data-cy="home_widget_info_sitesInProduction">
                                                        <h3 class="kt-widget1__title">{{ $t("troubleshooting_goodCoverageTitle") }}</h3>
                                                        <span class="kt-widget1__desc">{{ $t("troubleshooting_goodCoverageSubTitle") }}</span>
                                                    </div>
                                                    <span class="kt-widget1__number kt-font-success" style=" font-size: 2rem; ">{{ numberOfTagWithGoodCoverage }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <div
                                        class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile"
                                    >
                                        <div
                                            class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm"
                                        >
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M12,13.8453624 L12,18.758963 L15.3125397,16.8266482 L12,13.8453624 Z M10,12.0453624 L6.33103527,8.74329415 C5.92052525,8.37383513 5.88724683,7.74154529 6.25670585,7.33103527 C6.62616487,6.92052525 7.25845471,6.88724683 7.66896473,7.25670585 L17.6689647,16.2567059 C18.172608,16.7099848 18.0891527,17.5223646 17.503871,17.8637789 L11.503871,21.3637789 C10.8372144,21.7526619 10,21.2717908 10,20.5 L10,12.0453624 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M12,10.4375595 L15.3984747,7.54885603 L12,5 L12,10.4375595 Z M10,3 C10,2.17595468 10.9407637,1.70557281 11.6,2.2 L17.6,6.7 C18.1131546,7.08486597 18.1363893,7.84650959 17.6476484,8.26193932 L7.64764842,16.7619393 C7.22684095,17.1196257 6.59574703,17.0684559 6.23806068,16.6476484 C5.88037434,16.226841 5.93154411,15.595747 6.35235158,15.2380607 L10,12.1375595 L10,3 Z" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3
                                                    class="kt-portlet__head-title"
                                                >{{ $t("troubleshooting_tagsHealthList") }}</h3>
                                            </div>

                                            <div v-if="arrayOfTags && arrayOfTags.length > 0" class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchBySerial')" @keyup="onSearch" v-model="searchedValue" id="act_searchInput" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span><i class="la la-search"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button @click="refreshTagsHealthTable" id="troubleshooting_refreshTagssHealthTable" class="exportXLSXButton btn btn-sm btn-outline-brand kt-margin-l-10">
                                                    <i class="flaticon-refresh"></i>
                                                    {{ $t("common_refreshTable") }}
                                                </button>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="TAGSHEALTH_LIST" mainTextKey="troubleshooting_emptyTagsHealth" subTextKey="troubleshooting_emptyTagsHealthSubLabel" imgName="empty_tag.svg" v-bind:isDisplayed="!arrayOfTags || arrayOfTags.length == 0"></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="tagsHealth_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <app-gatewayscoverage v-bind:gateways="tagGatewaysCoverage" v-bind:selectedTag="selectedTag"></app-gatewayscoverage>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "./../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import TagGatewaysCoverage from "../modals/taggatewayscoveragemodal.vue";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteTagsHealthTable: null,
            searchedValue: "",
            tagGatewaysCoverage: [],
            selectedTag: null
        };
    },
    created: function() {
        console.log("Component(siteTagsHealth)::created() - called");
        // Load user sites from backend and store the result in sites store.
        this.getSiteTagsHealth(this.siteId);
        this.getSiteTags(this.siteId);
        this.getSiteAutoCalibrationTags(this.siteId);
    },
    mounted: function() {
        console.log("Component(siteTagsHealth)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(siteTagsHealth)::destroyed() - called");
        this.resetSiteHealthState();
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteTagsHealth: function() {
            console.log("Component(siteTagsHealth)::watch(siteTagsHealth) called");
            this.initKtDataTable();
        },
        user: function(user) {
            if (user) {
                console.log("Component(siteTagsHealth)::watch(user) called with :", user);
                this.initKtDataTable();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --teAnoth
        ...mapGetters(["siteTagsHealth", "user", "siteTags", "siteAutoCalibrationTags"]),

        arrayOfTags() {
            return this.siteTagsHealth.filter((tag) => {
                return tag.type === "Tag"
            }).map((tag) => {
                if (tag.coverage.length == 0) {
                    tag.status = i18n.t("troubleshooting_tagStatusNone");
                } else if (tag.coverage.length == 1 || tag.coverage.length == 2) {
                    tag.status = i18n.t("troubleshooting_tagStatusBad");
                } else if (tag.coverage.length == 3) {
                    tag.status = i18n.t("troubleshooting_tagStatusGood");
                } else {
                    tag.status = i18n.t("troubleshooting_tagStatusExcellent");
                }
                return tag;
            });
        },

        numberOfTagWithGoodCoverage() {
            var counter = 0;
            for (var tag of this.arrayOfTags) {
                if (tag.coverage.length >= 3) {
                    counter += 1;
                }
            }
            return counter;
        },

        numberOfTagWithBadCoverage() {
            var counter = 0;
            for (var tag of this.arrayOfTags) {
                if (tag.coverage.length < 3) {
                    counter += 1;
                }
            }
            return counter;
        }

    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteTagsHealth", "getSiteTags", "getSiteAutoCalibrationTags", "resetSiteHealthState"]),

        initKtDataTable() {
            if (this.siteTagsHealth != null) {
                // If site table has been already build, destroy the table before creating a fresh instance !
                if (this.siteTagsHealthTable) {
                    $("#tagsHealth_datatable").KTDatatable().destroy();
                }
                // According to source list length, show datatable or empty list icon+text
                if (this.arrayOfTags.length > 0) {
                    // There is data => Hide empty list icon+text

                    const options = {
                        data: {
                            type: "local",
                            source: commonVueHelper.preventHtmlInjection(this.arrayOfTags),
                            pageSize: 20,
                            saveState: {
                                webstorage: false
                            }
                        },
                        layout: commonVueHelper.getLayoutOptions(),
                        translate: commonVueHelper.getTranslateOptions(),
                        toolbar: commonVueHelper.getToolbarOptions(),
                        sortable: true,
                        pagination: true,
                        columns: [
                            {
                                field: "serialNumber",
                                title: i18n.t("tag_serialNumberLabel"),
                                textAlign: "center",
                                overflow: "visible",
                                autoHide: false,
                                template: (row) => {
                                    if (this.user.isTSUser){
                                        return row.serialNumber;
                                    } else {
                                        return (
                                            '\
                                            <div class="kt-user-card-v2">\
                                                <div class="kt-user-card-v2__details openResourceLink">\
                                                    <a class="editTag kt-link kt-user-card-v2__name" data-tagid="' +
                                            row.id +
                                            '" href="javascript:;">' +
                                            row.serialNumber +
                                            "</a>\
                                                </div>\
                                            </div>\
                                        "
                                        );
                                    }
                                }
                            },
                            {
                                field: "macAddress",
                                title: i18n.t("troubleshooting_tagMacAddressLabel"),
                                textAlign: "center"
                            },
                            {
                                field: "coverage",
                                title: i18n.t("troubleshooting_tagNumberAPCoverageLabel"),
                                overflow: "visible",
                                autoHide: false,
                                width: 150,
                                textAlign: "center",
                                template: function(row) {
                                    if (row.coverage.length == 0) {
                                        return i18n.t("troubleshooting_tagNoGatewayScanned");
                                    } else if (row.coverage.length == 1) {
                                        return i18n.t("troubleshooting_tagOneGatewayScanned");
                                    } else {
                                        return row.coverage.length + " " + i18n.t("troubleshooting_tagMultipleGatewayScanned");
                                    }
                                }
                            },
                            {
                                field: "status",
                                title: i18n.t("troubleshooting_tagStatus"),
                                textAlign: "center",
                                template: function(row) {
                                    if (row.coverage.length == 0) {
                                        return (
                                            '<span class="kt-badge kt-badge--danger kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-danger">' +
                                            i18n.t("troubleshooting_tagStatusNone") +
                                            "</span>"
                                        );
                                    }
                                    else if (row.coverage.length == 1 || row.coverage.length == 2) {
                                        return (
                                            '<span class="kt-badge kt-badge--warning kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-warning">' +
                                            i18n.t("troubleshooting_tagStatusBad") +
                                            "</span>"
                                        );
                                    } else if (row.coverage.length == 3) {
                                        return (
                                            '<span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">' +
                                            i18n.t("troubleshooting_tagStatusGood") +
                                            "</span>"
                                        );
                                    } else {
                                        return (
                                            '<span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">' +
                                            i18n.t("troubleshooting_tagStatusExcellent") +
                                            "</span>"
                                        );
                                    }
                                }
                            },
                            {
                                field: "Actions",
                                title: i18n.t("common_actions"),
                                sortable: false,
                                width: 110,
                                overflow: "visible",
                                autoHide: false,
                                textAlign: "center",
                                template: function(row) {
                                    if (row.coverage.length === 0){
                                        return "-";
                                    } else {
                                        return (
                                            `
                                                <button data-toggle="kt-tooltip" data-placement="top" type="button" data-tagid="` +
                                                row.id +
                                                `" class="btn btn-outline-brand btn-sm showCoverageGateway">` +
                                                i18n.t("troubleshooting_tagMoreDetail") +
                                                `</button>
                                            `
                                        );
                                    }
                                }
                            }
                        ]
                    };

                    // Now, we are ready to build the table
                    this.siteTagsHealthTable = $("#tagsHealth_datatable").KTDatatable(options);

                    // Once table is build, register click events on buttons inside the table
                    this.registerEventsOnSiteTagsHealthTable();

                } else {
                    // There is no data => Show empty list icon+text
                    // No table process: Comment lines below because table destroy previously
                    this.siteTagsHealthTable = null;
                }
            }
        },

        onSearch() {
            if (this.siteTagsHealthTable) {
                this.siteTagsHealthTable.search(this.searchedValue, "serialNumber");
            }
        },

        registerEventsOnSiteTagsHealthTable() {

            var self = this;

            $("#tagsHealth_datatable").on("kt-datatable--on-layout-updated", () => {

                $(".editTag").off().on("click", function() {
                    let tagId = $(this).attr("data-tagid");
                    console.log("Component(siteTagsHealth)::registerEventsOnSiteTagsHealthTable(clickOnGatewayName) clicked. TagId = " +tagId);
                    // Hide and destroy element’s tooltip
                    $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                    // Research if is a fixed tag or mobile tag
                    if (_.find(self.siteTags, {id: tagId})) {
                        // Open edit tag vue
                        self.onEditTag(tagId)
                    } else {
                        if (_.find(self.siteAutoCalibrationTags, {id: tagId})) {
                            // Open autocalibration tag vue
                            self.onEditAutoCalibrationTag(tagId);
                        }
                    }
                });

                $(".showCoverageGateway").off().on("click", function() {
                    let tagId = $(this).attr("data-tagid");
                    console.log("Component(siteTagsHealth)::registerEventsOnSiteTagsHealthTable(showCoverageGateway) clicked. TagId = " + tagId);
                    // Hide and destroy element’s tooltip
                    $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                    // Add the gateway which coverage the tag identified by its id
                    var tag = _.find(self.arrayOfTags, {id: tagId});
                    if (tag) {
                        self.selectedTag = tag;
                        self.tagGatewaysCoverage = tag.coverage;
                        $("#taggatewayscoverage").modal("show");
                    }
                });

                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");
                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({
                        trigger: "hover"
                    });
                }, 50);

            });

        },

        onEditTag: function(tagId) {
            this.$router.push({ name: "editTag", params: { tagId: tagId, siteId: this.siteId } });
        },

        onEditAutoCalibrationTag: function(autoCalibrationTagId) {
            this.$router.push({
                name: "editAutoCalibrationTag",
                params: { autoCalibrationTagId: autoCalibrationTagId, siteId: this.siteId }
            });
        },

        refreshTagsHealthTable: function() {
            // Load user sites from backend and store the result in sites store.
            this.getSiteTagsHealth(this.siteId);
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-gatewayscoverage": TagGatewaysCoverage
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
